.sidebar-item-icon {
  img {
    max-width: 22px;
  }
}

#pick-up-host {
}

.pick-up-host-print-button {
  padding: 1px 10px;
  height: auto;
  font-size: 16px;
  background: $brand;
  border-color: $brand;
  width: auto;
  text-align: right;
}

@media print {
  .farmer-host-sidebar,
  .card-header-button,
  .pick-up-host-load-more-button,
  .pick-up-host-print-button {
    display: none;
  }
}

.pick-up-host-group {
  border-color: $tan-dark;
  @extend .shadowed;
  margin-bottom: 30px;
  border-radius: 4px !important;
  border-bottom: 1px solid $tan-dark !important;

  .card-header {
    border-bottom-color: transparent;
    position: relative;
    margin-bottom: 0 !important;
    background: darken($tan, 2%);

    &:hover {
      cursor: pointer;
    }

    .card-header-button {
      position: absolute;
      top: 50%;
      right: 20px;
      //height: 30px;
      background: $brand-tertiary;
      padding: 3px 10px;
      color: white;
      margin-top: -12px;
      font-size: 14px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .table td {
    line-height: 1.1;
  }
}

.pick-up-host-accordion-heading {
  font-weight: 500;
  font-size: 24px;
  width: calc(100% - 140px);
}

.pick-up-host-accordion-subheading {
  font-size: 18px;
  width: calc(100% - 140px);
}

table .table-no-border {
  border-top: none;
}

.pick-up-host-table-customer {
  border-bottom: 1px solid $light;

  &:last-child {
    border-bottom: none;
  }
}

.pick-up-host-load-more-button {
  padding: 3px 20px;
  background: $brand-secondary;
  border: $brand-secondary;
  width: auto;
}

.pick-up-host-admin-outer-group {
  margin-bottom: 30px;
}

.pick-up-host-admin-outer-group-card-header {
  // filter: saturate(0.5);
  //filter: brightness(.9);
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 6px solid;
}

.pick-up-host-admin-outer-group-card-title {
}
